<template>
  <div
    class="container"
    id="program"
    v-title
    :data-title="$t('i18n.programList')"
  >
    <div id="outer-title">{{ $t("i18n.programList") }}</div>
    <div v-loading="loading">
      <jl-table
        :tableData="tableData"
        @init="init"
        :options="options"
        :columns="columns"
        :operates="operates"
        :total="total"
        :height="$defined.HEIGHT - 360 + 'px'"
        @handleSelectionChange="handleSelectionChange"
      >
      </jl-table>
    </div>
    <el-dialog
      :title="$t('i18n.audit')"
      v-model="dialogAuditVisible"
      :close-on-click-modal="false"
      destroy-on-close
    >
      <jl-form :columns="auditColumns" :option="btnOption"></jl-form>
    </el-dialog>
    <el-dialog
      custom-class="previewDialog"
      :title="$t('i18n.preview')"
      v-model="dialogPreviewVisible"
      :close-on-click-modal="false"
      destroy-on-close
    >
      <preview :content="info"></preview>
    </el-dialog>
    <el-dialog
      :title="$t('i18n.device')"
      v-model="dialogDeviceVisible"
      :close-on-click-modal="false"
      destroy-on-close
      width="80%"
    >
      <jl-table
        :tableData="deviceData"
        :options="deviceOptions"
        :columns="deviceColumns"
        :operates="deviceOperates"
        :height="$defined.HEIGHT - 360 + 'px'"
      >
      </jl-table>
      <template #footer>
        <el-button type="cancel" @click="dialogDeviceVisible = false">
          {{ $t("i18n.cancel") }}
        </el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
import { useI18n } from "vue-i18n";
import jlTable from "../../components/table";
import jlForm from "../../components/form";
import preview from "./Preview";
import { useRouter } from "vue-router";
export default {
  name: "ProgramList",
  components: {
    jlTable,
    jlForm,
    preview,
  },
  setup(props, { emit }) {
    emit("public_header", true);
    const { proxy } = getCurrentInstance();
    const { t } = useI18n();
    const router = useRouter();
    const state = reactive({
      formInline: {
        pageNumber: 1,
        pageSize: 10,
      },
      // table数据
      tableData: [],
      options: {
        // table 的参数
        isAdd: false, // 是否启用新增功能
        isEdit: false, // 是否启用编辑功能
        isDelete: false, // 是否启用删除功能
        highlightCurrentRow: false, // 是否支持当前行高亮显示
        multiSelect: true, // 是否支持列表项选中功能
        pageShow: true, // 是否翻页
      },
      columns: [
        // 需要展示的列
        {
          prop: "name",
          label: "programName",
          width: 180,
        },
        {
          prop: "screen",
          label: "playArea",
          align: "center",
          search: true,
          type: "select",
          props: { label: "locale", value: "value" },
          data: [
            { locale: "all", value: "" },
            { locale: "homeScreen", value: "1" },
            { locale: "secondaryScreen", value: "2" },
          ],
          formatter: (row) => {
            return row.screen == 1
              ? t("i18n.homeScreen")
              : t("i18n.secondaryScreen");
          },
        },
        {
          label: "broadcastDate",
          align: "center",
          width: 210,
          formatter: (row) => {
            return row.effectiveDateBegin + "~" + row.effectiveDateEnd;
          },
        },
        {
          label: "timeSlot",
          align: "center",
          width: 180,
          formatter: (row) => {
            return row.effectiveTimeBegin + "~" + row.effectiveTimeEnd;
          },
        },
        {
          label: "programDuration",
          align: "center",
          formatter: (row) => {
            return row.duration + "s";
          },
        },
        {
          prop: "createdDate",
          label: "createdDate",
          align: "center",
          width: 100,
          formatter: (row) => {
            return row.createdDate.split(" ")[0];
          },
        },
        {
          prop: "status",
          label: "status",
          align: "center",
          formatter: (row) => {
            if (row.status == 0) {
              return `<span>${t("i18n.toBeReviewed")}</span>`;
            } else if (row.status == 1) {
              return `<span style="color:#67c23a">
                ${t("i18n.available")}
              </span>`;
            } else if (row.status == 2) {
              return `<span style="color:#f56c6c">
                ${t("i18n.unavailable")}
              </span>`;
            }
          },
        },
        {
          prop: "screen",
          label: "device",
          align: "center",
          formatter: () => {
            return `<a>${t("i18n.device")}</a>`;
          },
          method: (row) => {
            proxy.$api.advert.getDeviceByProgram(row.id).then((res) => {
              state.dialogDeviceVisible = true;
              state.deviceData = res.data;
            });
          },
        },
        {
          label: "operation",
          width: 270,
          formatter: (row) => {
            let str = `<a onclick="handlePreview(event)" data-id="${row.id}">
              <i class="el-icon-view"></i>&nbsp;${t("i18n.preview")}
            </a>`;
            if (row.status == 0) {
              if (proxy.$defined.btnPermission("编辑节目")) {
                str += `<a onclick="addEditProgramData(event)"
                  data-id="${row.id}">
                  <i class="el-icon-edit"></i>&nbsp;${t("i18n.edit")}</a>`;
              }
              if (proxy.$defined.btnPermission("审核节目")) {
                str += `<a onclick="programAudit(event)" data-id="${row.id}">
                  <i class="el-icon-finished"></i>&nbsp;${t("i18n.audit")}
                </a>`;
              }
            } else if (row.status == 1) {
              if (proxy.$defined.btnPermission("发布节目")) {
                str += `<a onclick="publishProgram(event)" data-id="${row.id}">
                  <i class="el-icon-document-add"></i>&nbsp;
                  ${t("i18n.releaseProgram")}
                </a>`;
              }
            }
            if (proxy.$defined.btnPermission("删除节目")) {
              str += `<a onclick="deleteProgram(event)" data-id="${row.id}">
                <i class="el-icon-delete"></i>&nbsp;${t("i18n.delete")}
              </a>`;
            }
            return str;
          },
        },
      ],
      operates: {
        // 列操作按钮
        width: 200,
        fixed: "right",
        list: [
          {
            label: "new",
            icon: "el-icon-plus",
            class: "add",
            show: proxy.$defined.btnPermission("添加节目"),
            type: "outer",
            method: () => {
              addEditProgramData("add");
            },
          },
          {
            label: "batchAudit",
            icon: "el-icon-finished",
            class: "orange",
            show: proxy.$defined.btnPermission("审核节目"),
            type: "outer",
            method: () => {
              var ids = state.multipleSelection;
              if (ids.length > 0) {
                state.auditColumns[0].isUpdate = true;
                state.auditColumns[0].value = ids.join(",");
                state.dialogAuditVisible = true;
              } else {
                proxy.$defined.tip(t("i18n.chooseProgram"));
              }
            },
          },
          {
            label: "batchDelete",
            icon: "el-icon-delete",
            class: "import",
            show: proxy.$defined.btnPermission("删除节目"),
            type: "outer",
            method: () => {
              var ids = state.multipleSelection;
              if (ids.length > 0) {
                handleDeleteProgram(ids.join(","));
              } else {
                proxy.$defined.tip(t("i18n.chooseProgram"));
              }
            },
          },
        ],
      },
      total: 0,
      loading: true,
      editData: {},
      dialogAuditVisible: false,
      auditColumns: [
        {
          prop: "id",
          row: false,
        },
      ],
      btnOption: [
        {
          label: "adopt",
          class: "primary",
          show: true,
          method: (row) => {
            auditProgram(row.id, 0);
          },
        },
        {
          label: "refuse",
          class: "export",
          show: true,
          method: (row) => {
            auditProgram(row.id, 1);
          },
        },
      ],
      multipleSelection: [],
      dialogPreviewVisible: false,
      info: "",
      dialogDeviceVisible: false,
      deviceData: "",
      deviceColumns: [
        // 需要展示的列
        {
          prop: "name",
          label: "deviceName",
          width: 180,
        },
        {
          prop: "mac",
          label: "deviceMAC",
          type: "input",
        },
        {
          prop: "sn",
          label: "deviceSN",
          type: "input",
        },
        {
          prop: "status",
          label: "status",
          align: "center",
          width: 140,
          formatter: (row) => {
            if (row.status == 1) {
              return `<span style="color:#67c23a">${t("i18n.online")}</span>`;
            } else {
              return `<span style="color:#f56c6c">${t("i18n.offline")}</span>`;
            }
          },
        },
      ],
      deviceOptions: {
        isAdd: false, // 是否启用新增功能
        isEdit: false, // 是否启用编辑功能
        isDelete: false, // 是否启用删除功能
        highlightCurrentRow: false, // 是否支持当前行高亮显示
        multiSelect: false, // 是否支持列表项选中功能
        pageShow: false, // 是否翻页
      },
      deviceOperates: {
        // 列操作按钮
        width: 200,
        fixed: "right",
        list: [],
      },
    });

    const init = async (params) => {
      state.loading = true;
      params.current = params.page;
      params.size = params.pageSize;
      params.descs = "created_date";
      const { data } = await proxy.$api.advert.programList(params);
      state.tableData = data.records;
      state.total = data.total;
      state.loading = false;
    };

    window.deleteProgram = async (e) => {
      let id = e.target.dataset.id;
      handleDeleteProgram(id);
    };

    const handleDeleteProgram = async (ids) => {
      proxy.$defined.confirm(
        () => {
          proxy.$api.advert.deleteProgram(ids).then(() => {
            init(state.formInline);
            proxy.$defined.tip(t("i18n.delete") + t("i18n.success"), "success");
          });
        },
        t("i18n.doYouWantToDelete"),
        t("i18n.delete")
      );
    };

    window.programAudit = (e) => {
      let id = e.target.dataset.id;
      state.auditColumns[0].isUpdate = true;
      state.auditColumns[0].value = id;
      state.dialogAuditVisible = true;
    };

    window.handlePreview = (e) => {
      // 预览
      let id = e.target.dataset.id;
      let row = state.tableData.filter((item) => {
        return item.id == id;
      });
      if (row[0].content) {
        state.dialogPreviewVisible = true;
        state.info = row[0].content;
      } else {
        proxy.$defined.tip(t("i18n.previewAfterEdit"));
      }
    };

    window.publishProgram = async (e) => {
      let id = e.target.dataset.id;
      let { data } = await proxy.$api.advert.getDeviceByProgram(id);
      let ids = [];
      data.map((item) => {
        ids.push(item.id);
      });
      proxy.$api.advert.publishProgram(ids.join(","), id).then(() => {
        proxy.$defined.tip(t("i18n.edit") + t("i18n.success"), "success");
      });
    };

    window.addEditProgramData = (e) => {
      let id = e.target.dataset.id;
      addEditProgramData(id);
    };

    const addEditProgramData = (id) => {
      router.push({
        path: "/advert/createProgram",
        query: { id: id === "add" ? "" : id },
      });
    };

    const auditProgram = async (ids, status) => {
      await proxy.$api.advert.auditProgram(ids, status);
      init(state.formInline);
      state.dialogAuditVisible = false;
    };

    const handleSelectionChange = (val) => {
      let ids = [];
      val.map((item) => {
        ids.push(item.id);
      });
      state.multipleSelection = ids;
    };

    return {
      ...toRefs(state),
      init,
      auditProgram,
      handleSelectionChange,
      handleDeleteProgram,
      addEditProgramData,
    };
  },
};
</script>

<style lang="scss">
#program {
  .jlForm {
    .form-button {
      margin: 0;
      text-align: center;
    }
  }
}
.previewDialog {
  text-align: center;
  margin-top: 40px !important;
  .el-dialog {
    width: auto;
    display: inline-block;
    .el-dialog__header {
      text-align: left;
    }
  }
  .el-dialog__body {
    padding: 0;
  }
}
</style>
