<template>
  <div
    id="preview"
    class="container"
    v-title
    :data-title="$t('i18n.matterList')"
  >
    <div class="outer" :style="screenStyle">
      <div class="screenInfo">
        <div
          class="rec"
          v-for="item in screen.content"
          :key="item.index"
          :style="{
            width: item.width + 'px',
            height: item.height + 'px',
            background: item.bg,
            top: item.top + 'px',
            left: item.left + 'px',
          }"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, watch } from "vue";
import { useRouter } from "vue-router";
export default {
  name: "Preview",
  props: {
    content: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    emit("public_header", true);
    const useRouterCurrent = reactive(useRouter());
    watch(useRouterCurrent, () => {
      init();
    });
    const state = reactive({
      screen: "",
      screenStyle: "",
    });

    const init = () => {
      // direction 0竖屏 1横屏
      let screen = (state.screen = JSON.parse(props.content));
      state.screen.content.map((item) => {
        item.width = item.w > 0 ? item.w : item.width;
        item.height = item.h > 0 ? item.h : item.height;
        item.top = item.y > 0 ? item.y : item.top;
        item.left = item.x > 0 ? item.x : item.left;
      });
      let size = screen.resolution.split("x");
      let w = screen.direction == 1 ? size[0] : size[1];
      let h = screen.direction == 1 ? size[1] : size[0];
      state.screenStyle = {
        width: parseInt(w / 2) + 40 + "px",
        height: parseInt(h / 2) + 40 + "px",
      };
    };

    init();

    return {
      ...toRefs(state),
    };
  },
};
</script>
<style lang="scss">
#preview {
  padding: 0 0 30px;
  margin: 0 20px;
  .outer {
    padding: 20px;
    background: #5c5d5c;
    border-radius: 10px;
    margin: 0 auto;
    .screenInfo {
      background: #000;
      border-radius: 10px;
      height: 100%;
      position: relative;
      .rec {
        position: absolute;
      }
    }
  }
}
</style>
